import React, { useState, useEffect } from 'react';
import { Modal, Pagination, Carousel } from 'react-bootstrap';
import { ChevronLeft, ChevronRight, X } from 'lucide-react';
import Marquee from 'react-fast-marquee';
import './Gallery.css';

const Gallery = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 12;

  const images = [];

  for (let i = 1; i <= 26; i++) {
    images.push({
      id: i,
      url: `img/g2/rts (${i}).jpg`,
      title: `Solar ${i}` 
    });
  }

  // Get current images for pagination
  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = images.slice(indexOfFirstImage, indexOfLastImage);
  const totalPages = Math.ceil(images.length / imagesPerPage);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const navigateImage = (direction) => {
    const currentIndex = images.findIndex(img => img.id === selectedImage.id);
    let newIndex;
    
    if (direction === 'next') {
      newIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
    } else {
      newIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
    }
    
    setSelectedImage(images[newIndex]);
  };

  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (!showModal) return;

      switch (e.key) {
        case 'ArrowLeft':
          navigateImage('prev');
          break;
        case 'ArrowRight':
          navigateImage('next');
          break;
        case 'Escape':
          setShowModal(false);
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [showModal, selectedImage]);

  return (
    <div className="container-fluid py-4">
         <h2 className="text-center mb-4">Our Project Gallery</h2>
      {/* Top Carousel Section */}
      <div className="marquee-section mb-5">
        <Marquee
          gradient={false}
          speed={40}
          pauseOnHover={true}
          className="gallery-marquee"
        >
          {images.map((image) => (
            <div 
              key={image.id} 
              className="marquee-item"
              onClick={() => handleImageClick(image)}
            >
              <img
                src={image.url}
                alt={image.title}
                className="marquee-image"
              />
              <div className="marquee-overlay">
                <p className="marquee-title">{image.title}</p>
              </div>
            </div>
          ))}
        </Marquee>
      </div>

   
      
      {/* Gallery Grid */}
      <div className="row g-4">
        {currentImages.map((image) => (
          <div key={image.id} className="col-6 col-sm-4 col-md-3">
            <div 
              className="gallery-item" 
              onClick={() => handleImageClick(image)}
            >
              <img
                src={image.url}
                alt={image.title}
                className="gallery-image"
              />
              <div className="gallery-overlay">
                <p className="gallery-title">{image.title}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="d-flex justify-content-center mt-4">
          <Pagination>
            <Pagination.First 
              onClick={() => handlePageChange(1)} 
              disabled={currentPage === 1}
            />
            <Pagination.Prev 
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            
            {[...Array(totalPages)].map((_, index) => (
              <Pagination.Item
                key={index + 1}
                active={currentPage === index + 1}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            
            <Pagination.Next 
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last 
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </div>
      )}

      {/* Preview Modal */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        centered
        className="gallery-modal"
      >
        <button className="modal-close" onClick={() => setShowModal(false)}>
          <X size={24} />
        </button>
        
        <button 
          className="modal-nav modal-prev" 
          onClick={() => navigateImage('prev')}
          aria-label="Previous image"
        >
          <ChevronLeft size={36} />
        </button>

        <button 
          className="modal-nav modal-next" 
          onClick={() => navigateImage('next')}
          aria-label="Next image"
        >
          <ChevronRight size={36} />
        </button>

        <Modal.Body className="p-0">
          {selectedImage && (
            <>
              <img
                src={selectedImage.url}
                alt={selectedImage.title}
                className="modal-image"
              />
              <div className="modal-caption">
                <h3>{selectedImage.title}</h3>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Gallery;