import React, { useState } from 'react';
import './flickity.css';

const ImgCarousel = () => {
  const [previewImage, setPreviewImage] = useState(null);

  const images = [
    { id: 1, url: 'img/gallery/gi1.png', title: 'Nature 1' },
    { id: 2, url: 'img/gallery/gi2.png', title: 'Nature 2' },
    { id: 3, url: 'img/gallery/gi3.png', title: 'Nature 3' },
    { id: 4, url: 'img/gallery/gi4.png', title: 'Nature 4' },
    { id: 5, url: 'img/gallery/gi5.png', title: 'Nature 5' },
    { id: 6, url: 'img/gallery/gi6.png', title: 'Nature 6' },
  ];

  const handleImageClick = (image) => {
    setPreviewImage(image);
  };

  const closePreview = () => {
    setPreviewImage(null);
  };

  return (
    <div className="img-carousel-container">
      {/* Heading */}
      <h2 className="text-center mb-4">Our Projects</h2>

      <div className="img-carousel">
        {images.map((image) => (
          <div
            key={image.id}
            className="img-carousel-item"
            onClick={() => handleImageClick(image)}
            style={{ cursor: 'pointer' }}
          >
            <img
              src={image.url}
              alt={image.title}
              className="img-carousel-image"
            />
            <div className="img-carousel-caption">{image.title}</div>
          </div>
        ))}
      </div>

      {/* Preview Modal */}
      {previewImage && (
        <div className="preview-modal">
          <div className="preview-overlay" onClick={closePreview}></div>
          <div className="preview-content">
            <img
              src={previewImage.url}
              alt={previewImage.title}
              className="preview-image"
            />
            <h3 className="preview-title">{previewImage.title}</h3>
            <button className="close-btn" onClick={closePreview}>
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImgCarousel;
